/**
 * 处理上传前的文件
 * @param file
 * @returns {boolean}
 */
export function handleBeforeUpload(file){
    //修改上传大小为20m
    return file.size <= 20971520;
}
//处理添加列表中的元素
export function handleUpdateSuccess(response,file,value){
    if (response.code === 0){
        const myFile = {name:file.name,url:response.data.url,uid:file.uid}
        value.push(myFile)
        return true;
    }else {
        return  false;
    }
}
//处理删除列表中的元素
export function handleRemove(file,value){
    const list = value
    list.some((item, i)=>{
        if (item.uid === file.uid){
            list.splice(i,1)
        }
    })
}
//处理上传中失败的
export function handleError(err, file, fileList){
    // this.$message.error("上传失败"+err)
    // this.loading=false
}