<template>
  <div class="bing" v-loading="loading">
<!--    <h1 class="title">麻醉-艾司洛尔病例观察表</h1>-->

    <el-alert
        title="请将图片中患者姓名、家庭住址、联系方式等进行打码处理，保护患者隐私，避免法律纠纷，由于图片没有打码处理导致患者信息泄露，与本平台无关。"
        type="warning"
        :closable=false>
    </el-alert>

    <el-collapse style="margin-bottom: 75px"  v-if="submit_success===false" v-model="activeNames">
<!--        患者基本信息      -->
<!--      {{basic_patient_information }}-->
<!--      <br>-->
<!--      {{surgical_record}}-->
      <el-collapse-item  title="患者基本信息" name="1">
        <el-divider></el-divider>
        <el-form label-suffix=":"
                 :disabled="caseIsPASSED"
                 :rules="basic_patient_information_rules"
                 ref="basic_patient_information" :model="basic_patient_information">
<!--          图片识别    -->`
          <el-form-item  style="text-align: center" prop="image_list">
            <el-upload
                class="upload"
                ref="upload"
                :before-upload="b_image_beforeUpload"
                :action=update_image
                :on-success="b_image_success"
                :on-remove="b_image_remove"
                :file-list="basic_patient_information.image_list"
                :on-preview="handlePictureCardPreview"
                accept="image/*"
                list-type="picture">
              <el-button slot="trigger" >
                <i class="el-icon-camera"></i>&nbsp;点击拍照</el-button>
              <el-button style="margin-left: 10px;" @click="case_textRcognition(basic_patient_information.image_list)"  type="primary">
                <i class="el-icon-full-screen"></i>&nbsp;开始识别</el-button>
            </el-upload>
          </el-form-item>
<!--          打开示例图片-->
          <div style="width: 100%;text-align: right">
            <el-button type="info" size="mini" @click="b_sample_picture_show=true">示例图片</el-button>
          </div>
<!--          姓名    -->
<!--          <el-form-item label="姓名">-->
<!--            <el-input v-model="basic_patient_information.name" placeholder="姓名"></el-input>-->
<!--          </el-form-item>-->
<!--        性别      -->
          <el-form-item label="性别" prop="sex">
            <br>
            <el-radio-group v-model="basic_patient_information.sex">
              <el-radio label="男">男</el-radio>
              <el-radio label="女">女</el-radio>
            </el-radio-group>
          </el-form-item>
<!--          年龄    -->
          <el-form-item label="年龄" prop="age">
            <el-input v-model="basic_patient_information.age" placeholder="年龄"></el-input>
          </el-form-item>
<!--          住院号    -->
          <el-form-item label="住院号" prop="hospital_number">
            <el-input v-model="basic_patient_information.hospital_number" placeholder="请输入住院号">
              <template slot="append"><el-button type="primary" @click="HospitalNumberIsExist">检查</el-button></template>
            </el-input>
          </el-form-item>
<!--          既往史    -->
          <el-form-item label="现病史/既往史">
            <el-input v-model="basic_patient_information.past_history"
                      type="textarea" placeholder="请输入既往史"></el-input>
          </el-form-item>
<!--          诊断    -->
          <el-form-item label="诊断">
            <el-input type="textarea" placeholder="请输入诊断结果" v-model="basic_patient_information.diagnosis"></el-input>
          </el-form-item>
        </el-form>
      </el-collapse-item>
<!-- ------------------------------------------------------------------------------------------------ -->
      <el-collapse-item title="手术记录" name="2">
        <el-divider></el-divider>
        <el-form label-suffix=":" :rules="surgical_record_rules"
                 :disabled="caseIsPASSED"
                 ref="surgical_record"  :model="surgical_record">
<!--          图片识别    -->
          <el-form-item style="text-align: center" prop="image_list">
            <el-upload
                class="upload"
                ref="upload"
                :action=update_image
                :before-upload="s_image_beforeUpload"
                :on-remove="s_image_remove"
                :on-success="s_image_success"
                :file-list="surgical_record.image_list"
                :on-preview="handlePictureCardPreview"
                list-type="picture"
                accept="image/*">
              <el-button slot="trigger" >
                <i class="el-icon-camera"></i>&nbsp;点击拍照</el-button>
              <el-button style="margin-left: 10px;" @click="case_textRcognition(surgical_record.image_list)" type="primary">
                <i class="el-icon-full-screen" @click=""></i>&nbsp;开始识别</el-button>
            </el-upload>
          </el-form-item>
<!--          打开示例图片-->
          <div style="width: 100%;text-align: right">
            <el-button type="info" size="mini" @click="s_sample_picture_show=true">示例图片</el-button>
          </div>
<!--          ASA分级    -->
          <el-form-item label="ASA分级" prop="ASA">
            <el-input placeholder="请输入ASA分级" v-model="surgical_record.ASA"></el-input>
          </el-form-item>
<!--        术前诊断      -->
          <el-form-item label="术前诊断" prop="preoperative_diagnosis">
            <el-input placeholder="请输入术前诊断" v-model="surgical_record.preoperative_diagnosis"></el-input>
          </el-form-item>
<!--          实施手术/逆行手术    -->
          <el-form-item label="实施手术/逆行手术" prop="operation">
            <el-input placeholder="请输入实施手术/逆行手术" v-model="surgical_record.operation"></el-input>
          </el-form-item>
 <!--          麻醉方式/麻醉方法    -->
          <el-form-item label="麻醉方式/麻醉方法" prop="anaesthetization">
            <el-input placeholder="请输入麻醉方式/麻醉方法" v-model="surgical_record.anaesthetization"></el-input>
          </el-form-item>
<!--          爱洛用药使用时机   -->
          <el-form-item label="艾司洛尔用药使用时机" prop="medication">
            <el-checkbox-group v-model="surgical_record.medication">
              <br>
              <el-checkbox label="插管(插管前1.5~3min,0.5mg/kg推注)"></el-checkbox>
              <br>
              <el-checkbox label="拔管(插管前1.5~3min,0.5mg/kg推注)"></el-checkbox>
              <br>
              <el-checkbox label="术中持续使用">
                术中持续使用(术中全程，25~200μg/kg/<br>min持续输注,可根据心率血压调整)
              </el-checkbox>
              <br>
              <el-checkbox label="术中单次使用">
                术中单次使用(1mg/kg推注，30秒<br>即刻控制心率失常或高血压)
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
<!--          手术时长    -->
          <el-form-item label="手术时长(分钟)" prop="shoushu_time">
            <el-input placeholder="请输入手术时长" v-model="surgical_record.shoushu_time"></el-input>
          </el-form-item>
<!--          艾司洛尔    -->
          <el-form-item label="艾司洛尔(mg)" prop="esmolol">
            <el-input placeholder="请输入艾司洛尔全程总用量(mg)" v-model="surgical_record.esmolol"></el-input>
          </el-form-item>
<!--          麻醉开始    -->
          <el-form-item label="麻醉开始" prop="">
            <br>
            <el-row :gutter="2">
              <el-col :span="8">
                <el-row>
                  <el-col>
                    心率/脉搏(pulse)
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-input v-model="surgical_record.mazui.heart_rate"></el-input>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-col>
                    收缩压
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-input v-model="surgical_record.mazui.systolic_blood_pressure"></el-input>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-col>
                    舒张压
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-input v-model="surgical_record.mazui.diastolic_blood_pressure"></el-input>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form-item>
<!--          插管    -->
          <el-form-item label="插管">
            <br>
            <el-row :gutter="2">
              <el-col :span="8">
                <el-row>
                  <el-col>
                    心率/脉搏(pulse)
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-input v-model="surgical_record.intubation.heart_rate"></el-input>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-col>
                    收缩压
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-input v-model="surgical_record.intubation.systolic_blood_pressure"></el-input>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-col>
                    舒张压
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-input v-model="surgical_record.intubation.diastolic_blood_pressure"></el-input>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form-item>
<!--          手术开始    -->
          <el-form-item label="手术开始">
            <br>
            <el-row :gutter="2">
              <el-col :span="8">
                <el-row>
                  <el-col>
                    心率/脉搏(pulse)
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-input v-model="surgical_record.surgery_begins.heart_rate"></el-input>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-col>
                    收缩压
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-input v-model="surgical_record.surgery_begins.systolic_blood_pressure"></el-input>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-col>
                    舒张压
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-input v-model="surgical_record.surgery_begins.diastolic_blood_pressure"></el-input>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form-item>
<!--          手术结束    -->
          <el-form-item label="手术结束">
            <br>
            <el-row :gutter="2">
              <el-col :span="8">
                <el-row>
                  <el-col>
                    心率/脉搏(pulse)
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-input v-model="surgical_record.surgery_is_over.heart_rate"></el-input>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-col>
                    收缩压
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-input v-model="surgical_record.surgery_is_over.systolic_blood_pressure"></el-input>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-col>
                    舒张压
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-input v-model="surgical_record.surgery_is_over.diastolic_blood_pressure"></el-input>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form-item>
<!--          拔管    -->
          <el-form-item label="拔管">
            <br>
            <el-row :gutter="2">
              <el-col :span="8">
                <el-row>
                  <el-col>
                    心率/脉搏(pulse)
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-input v-model="surgical_record.extubation.heart_rate"></el-input>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-col>
                    收缩压
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-input v-model="surgical_record.extubation.systolic_blood_pressure"></el-input>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-col>
                    舒张压
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-input v-model="surgical_record.extubation.diastolic_blood_pressure"></el-input>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form-item>
<!--          出手术室    -->
          <el-form-item label="出手术室">
            <br>
            <el-row :gutter="2">
              <el-col :span="8">
                <el-row>
                  <el-col>
                    心率/脉搏(pulse)
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-input v-model="surgical_record.out_of_operating_room.heart_rate"></el-input>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-col>
                    收缩压
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-input v-model="surgical_record.out_of_operating_room.systolic_blood_pressure"></el-input>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-col>
                    舒张压
                  </el-col>
                </el-row>
                <el-row>
                  <el-col>
                    <el-input v-model="surgical_record.out_of_operating_room.diastolic_blood_pressure"></el-input>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form-item>
<!--          伴随用药    -->
          <el-form-item label="伴随用药" prop="concomitant_medication">
            <el-checkbox-group v-model="surgical_record.concomitant_medication">
              <br>
              <el-checkbox label="乌拉地尔" ></el-checkbox>
              <br>
              <el-checkbox label="右美托嘧啶" ></el-checkbox>
              <br>
              <el-checkbox label="丙泊酚" ></el-checkbox>
              <br>
              <el-checkbox label="芬太尼/舒芬太尼/瑞芬太尼/阿芬太尼"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>

        </el-form>
      </el-collapse-item>
    </el-collapse>

    <!--  脚步功能  -->
    <div class="footer">
      <!--    版本号     -->
      <p style="text-align: right;font-size: 2px;color: #afadad;margin-right: 5px">v2.2</p>
      <!--   提交按钮   -->
      <div class="myButton">
        <el-button :loading=submitButtonLoading  v-if="submit_success===false&&caseIsPASSED===false&&showSTAGEDButton===true"
                   @click = "beforeTemporarySave">
          暂存
        </el-button>
        <el-button :loading=submitButtonLoading  v-if="submit_success===false&&caseIsPASSED===false"
                    type="primary"
                    @click="onSubmitBefore">
          {{caseAuditStatus ==='PENDING'?"修改":"提交" }}
        </el-button>
      </div>

    </div>

    <Submit_success v-if="submit_success===true"></Submit_success>
    <MuBanSelect v-if="isSelect" :questionnaire_info="questionnaire_info" :select="isSelect"></MuBanSelect>
<!--    基本信息中的示例图片  -->
    <el-dialog
        title="示例图片"
        :visible.sync="b_sample_picture_show"
          width="80%">
      <img src="../../public/caseSamplePicture/mycase1.png" width="100%" style="margin-bottom: 5px">
      <img src="../../public/caseSamplePicture/mycase2.png" width="100%">
    </el-dialog>
<!--   手术记录中的示例图片 -->
    <el-dialog
        title="示例图片"
        :visible.sync="s_sample_picture_show"
        width="80%">
      <img src="../../public/caseSamplePicture/mycase3.png" width="100%" style="margin-bottom: 5px">
    </el-dialog>
<!--    提示需要用户验证  -->
    <el-dialog
        :close-on-click-modal = false
        :close-on-press-escape= false
        :show-close = false
        width="80%"
        title="提示"
        :visible.sync="isMemberCertStatus_show">
      您还没有认证<br>
      ①请您前往"云ICU"微信小程序<br>
      ②点击底部 我的-我的认证<br>
      ③提交证件照，等待审核通过。
    </el-dialog>
<!--    点击上传图片弹窗    -->
    <el-dialog :visible.sync="dialogVisible" width="95%">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

  </div>
</template>

<script>
import {handleBeforeUpload,handleUpdateSuccess,handleRemove,handleError} from '@/utils/imageUpdate'
import {
  addContent,
  caseById,
  caseTemplateId17HospitalNumber,
  isMemberCertStatus,
  textRcognition,
  updateCase
} from "@/service/api";
import MuBanSelect from "@/components/MuBanSelect";
import Submit_success from "@/components/SubmitSuccess";
export default {
  name: "Bing",
  components: {MuBanSelect, Submit_success},
  data(){
    return{
      //基本信息中的展示示例 图片
      b_sample_picture_show:false,
      s_sample_picture_show:false,
      //需要用户提交审核验证的弹框
      isMemberCertStatus_show:false,
      //是否提交成功
      submit_success:false,
      questionnaire_info:{},
      loading:false,
      update_image:"https://yun-new-admin-api.yunicu.com/api/admin/common/update",
      activeNames: ['1'],
      basic_patient_information:{
        // image_list:[ { "name": "烤鸡.png", "url": "https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/e4003c42-1924-4d89-b317-f42581db9e4c.png", "uid": 1646383438023 },
        //   { "name": "烤鸡.png", "url": "https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/e4003c42-1924-4d89-b317-f42581db9e4c.png", "uid": 1646383438024 }],
        image_list:[],
      },
      surgical_record:{
        image_list:[],
        medication:[],
        mazui:{
          heart_rate:''
        },
        intubation:{
          heart_rate:''
        },
        surgery_begins:{
          heart_rate:''
        },
        surgery_is_over:{
          heart_rate:''
        },
        extubation:{
          heart_rate:''
        },
        out_of_operating_room:{
          heart_rate:''
        },
        concomitant_medication:[],
      },

      basic_patient_information_rules:{
        image_list:[
          { required: true, message: '请添加基本信息图片', trigger: 'change' }
        ],
        name:[
          { required: true, message: '请完整输入', trigger: 'change' }
        ],
        sex:[
          { required: true, message: '请完整输入', trigger: 'change' }
        ],
        age:[
          { required: true, message: '请完整输入', trigger: 'change' }
        ],
        hospital_number:[
          { required: true, message: '请完整输入', trigger: 'change' }
        ],
        past_history:[
          { required: true, message: '请完整输入', trigger: 'change' }
        ],
        diagnosis:[
          { required: true, message: '请完整输入', trigger: 'change' }
        ]
      },

      surgical_record_rules:{
        image_list:[
          { required: true, message: '请添加手术记录图片', trigger: 'change' }
        ],
        ASA:[
          { required: true, message: '请完整输入', trigger: 'change' }
        ],
        preoperative_diagnosis:[
          { required: true, message: '请完整输入', trigger: 'change' }
        ],
        operation:[
          { required: true, message: '请完整输入', trigger: 'change' }
        ],
        anaesthetization:[
          { required: true, message: '请完整输入', trigger: 'change' }
        ],
        medication:[
          { required: true, message: '请完整输入', trigger: 'change' }
        ],
        shoushu_time:[
          { required: true, message: '请完整输入', trigger: 'change' }
        ],
        esmolol:[
          { required: true, message: '请完整输入', trigger: 'change' }
        ],
        concomitant_medication:[
          { type: 'array', required: true, message: '请至少选择一个', trigger: 'change' }
        ],
      },
      //==========内容回相关===========
      /**
       * 病例已通过审核
       */
      caseIsPASSED:false,

      //===============点击上传图片相关=======+
      dialogImageUrl: '',
      dialogVisible: false,
      //回显的状态 判断书否为暂存
      caseAuditStatus:'',
      //是否展现暂存的按钮
      showSTAGEDButton:true,
      //提交按钮改变状态等待
      submitButtonLoading:false,
    }
  },
  computed:{
    isSelect(){
      return this.$store.state.isSelect_button;
    }
  },
  mounted() {
    document.title="麻醉-艾司洛尔病例观察表"
    this.verification_isSelect();
  },

  methods: {

    /**
     * 验证是否添加模板，是否可以提交
     */
    async verification_isSelect() {
      //获取携带参数
      const questionnaire_info = this.$route.query
      /**
       * { "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IkZGMjVGMjY4LTM0OTUtNDY1MS05Mzc1LTA4M0NERTZBMjI5RSIsIm5hbWUiOiLpgrEifQ.7-7Q6smiAk1jnfbhUuwVeyGYFkd3amakm1Kqykimh8Q",
       * "patient_id": "2259",
       * "created_by": "FF25F268-3495-4651-9375-083CDE6A229E",
       * "template_id": "15",
       * "template_link": "https://questionnaire.yunicu.com/#/esmolol/m",
       * //已将添加模板为false，为添加模板为false
       * "isSelect": "true" }
       */
      // const questionnaire_info = { "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IkZGMjVGMjY4LTM0OTUtNDY1MS05Mzc1LTA4M0NERTZBMjI5RSIsIm5hbWUiOiLpgrEifQ.7-7Q6smiAk1jnfbhUuwVeyGYFkd3amakm1Kqykimh8Q",
      //   "patient_id": "2259",
      //   "created_by": "FF25F268-3495-4651-9375-083CDE6A229E",
      //   "template_id": "17",
      //   "template_link": "https://questionnaire.yunicu.com/#/esmolol/b",
      //   "isSelect": "false" }
      //
      // const questionnaire_info =
      // { "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IkZGMjVGMjY4LTM0OTUtNDY1MS05Mzc1LTA4M0NERTZBMjI5RSIsIm5hbWUiOiLpgrEifQ.7-7Q6smiAk1jnfbhUuwVeyGYFkd3amakm1Kqykimh8Q",
      //     "patient_id": "2260",
      //     "created_by": "FF25F268-3495-4651-9375-083CDE6A229E",
      //     "template_id": "17",
      //     "template_link": "https://questionnaire.yunicu.com/#/test",
      //     "isSelect": "false",
      //     "type": "see",
      //     "id": "28155" }
      //判断是否有资格填写问卷，只有审核通过的账号才有资格填写问卷
      if (questionnaire_info.created_by) {
        const response = await isMemberCertStatus(questionnaire_info.created_by);
        if (response.code === 0){
          //验证已通过
        }else {
          //验证未通过弹窗
          this.isMemberCertStatus_show = true
        }
      } else {
        //验证未通过弹窗
        this.isMemberCertStatus_show = true
      }


      //判断携带参数中是否有需要的参数，并且是否已经添加模板了
      /**
       * 添加模板后-才能提交
       * isSelect为true表示没有添加模板
       * isSelect为false表示已经添加模板了
       */
      if (questionnaire_info.isSelect === "true") {
        //有需要的参数，并且暂未添加模板
        this.$store.commit("submit_isSelect_button", true)
      } else {
        this.$store.commit("submit_isSelect_button", false)
      }
      this.questionnaire_info = questionnaire_info


      //判断是否需要回显，当参数中有"type": "see" 表示查看
      if (questionnaire_info.type === "see"){
        //需要回显
        await this.onSee(questionnaire_info)
        if (this.caseAuditStatus!=="STAGED"){
          this.showSTAGEDButton = false
        }
      }



    },

    //处理文件列表
    handleImageList(value){
      let image_list=[];
      for (let i in value){
        image_list[i] = value[i].url;
      }
      return image_list;
    },

    //病例识别
    async case_textRcognition(value){
      // { "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IkZGMjVGMjY4LTM0OTUtNDY1MS05Mzc1LTA4M0NERTZBMjI5RSIsIm5hbWUiOiLpgrEifQ.7-7Q6smiAk1jnfbhUuwVeyGYFkd3amakm1Kqykimh8Q",
      //     "patient_id": "2259",
      //     "created_by": "FF25F268-3495-4651-9375-083CDE6A229E",
      //     "template_id": "15",
      //     "template_link": "https://questionnaire.yunicu.com/#/esmolol/m",
              //已将添加模板为false，为添加模板为false
      //     "isSelect": "true" }
      // const data={
      //   "created_by":"FF25F268-3495-4651-9375-083CDE6A229E",
      //   "template_id":15,
      //   "image_list":this.handleImageList(value),
      //   "type":"resident_admit_note"
      // }
      const data={
        "created_by":this.questionnaire_info.created_by,
        "template_id":this.questionnaire_info.template_id,
        "image_list":this.handleImageList(value),
        "type":"resident_admit_note"
      }
      // const response = await textRcognition(data);
      // if (response.code === 0){
      //   this.$message.success("识别成功")
      //   return response.data;
      // }else {
      //   this.$message.error("识别识别")
      // }
    },


    //============================处理图片上传=============================
    //上传成功
    b_image_success(response, file){
      let b = handleUpdateSuccess(response,file,this.basic_patient_information.image_list);
      b?this.$message.success("上传成功。"):this.$message.error("上传失败"+response.message)
    },
    //删除元素
    b_image_remove(file, fileList){
      handleRemove(file,this.basic_patient_information.image_list)
    },
    b_image_beforeUpload(file){
      return handleBeforeUpload(file)
    },

    //上传成功
    s_image_success(response, file){
      let b = handleUpdateSuccess(response,file,this.surgical_record.image_list);
      b?this.$message.success("上传成功。"):this.$message.error("上传失败"+response.message)
    },
    //删除元素
    s_image_remove(file, fileList){
      handleRemove(file,this.surgical_record.image_list)
    },
    s_image_beforeUpload(file){
      return handleBeforeUpload(file)
    },


    //=============提交之前========
    async onSubmitBefore(){
      this.submitButtonLoading = true
      if (this.questionnaire_info.type === "see"){
        await this.updateCaseCount()
      }else {
        await this.onSubmit()
      }
      this.submitButtonLoading = false
    },

    //===============提交============
    async onSubmit(){
      if (await this.HospitalNumberIsExist()) {
        await this.$refs.basic_patient_information.validate(async (valid) => {
          if (valid) {
            await this.$refs.surgical_record.validate(async (valid) => {
              if (valid) {
                this.loading = true
                //新建一个新的数组，用于处理基本信息数据。
                let form = {};
                let basic_patient_information = this.basic_patient_information
                let surgical_record = this.surgical_record;
                basic_patient_information.show_image_list = this.handleImageList(basic_patient_information.image_list);
                surgical_record.show_image_list = this.handleImageList(surgical_record.image_list)
                form.basic_patient_information = basic_patient_information
                form.surgical_record = surgical_record
                //将所有图片放置到一个字段中
                let image_list = [];
                for (let i in basic_patient_information.show_image_list){
                  image_list.push(basic_patient_information.show_image_list[i])
                }
                for (let i in surgical_record.show_image_list){
                  image_list.push(surgical_record.show_image_list[i])
                }
                const questionnaireInfo = this.questionnaire_info;
                const data = {
                  "template_id": questionnaireInfo.template_id,
                  "template_link": questionnaireInfo.template_link,
                  "patient_id": questionnaireInfo.patient_id,
                  "created_by": questionnaireInfo.created_by,
                  "template_content": form,
                  "coding_image_list":image_list,
                  "audit_status": "PENDING"
                }
                const response = await addContent(data);
                if (response.code === 0) {
                  this.$message.success("提交成功！")
                  this.submit_success = true;
                } else {
                  this.$message.error("提交失败!" + response.message)
                }

              } else {
                this.activeNames = ['2']
                this.$message.error("提交失败！输入有空，请检查！")
                return false;
              }
            });
          } else {
            this.activeNames = ['1']
            this.$message.error("提交失败！输入有空，请检查！")
            return false;
          }
        });
      }
      this.loading = false
    },



    //=============回显==============
    async onSee(questionnaire_info){
      //1.获取id，调用接口获取具体内容
      const response = await caseById(questionnaire_info.id);
      if (response.code !== 0){
        this.$message.error("获取失败"+response.message)
      }else {
        //赋值
        this.surgical_record = response.data.template_content.surgical_record
        this.basic_patient_information = response.data.template_content.basic_patient_information
        this.caseAuditStatus = response.data.audit_status
        //判断是否已经透过审核
        if (response.data.audit_status === "PASSED"){
          this.caseIsPASSED = true
        }
      }
    },

    /**
     * 更新数据
     * @returns {Promise<void>}
     */
    async updateCaseCount(){
      //判断检查是否通过
      if (await this.HospitalNumberIsExist()){
        await this.$refs.basic_patient_information.validate(async (valid) => {
          if (valid) {
            await this.$refs.surgical_record.validate(async (valid) => {
              if (valid) {
                this.loading = true
                //新建一个新的数组，用于处理基本信息数据。
                let form={};
                let basic_patient_information = this.basic_patient_information
                let surgical_record = this.surgical_record;
                basic_patient_information.show_image_list = this.handleImageList(basic_patient_information.image_list);
                surgical_record.show_image_list = this.handleImageList(surgical_record.image_list)

                form.basic_patient_information = basic_patient_information
                form.surgical_record = surgical_record

                //将所有图片放置到一个字段中
                let image_list = [];
                for (let i in basic_patient_information.show_image_list){
                  image_list.push(basic_patient_information.show_image_list[i])
                }
                for (let i in surgical_record.show_image_list){
                  image_list.push(surgical_record.show_image_list[i])
                }

                const questionnaireInfo = this.questionnaire_info;
                const data={
                  "template_content":form,
                  "audit_status":"PENDING",
                  "coding_image_list":image_list,
                }
                const response = await updateCase(questionnaireInfo.id,data);
                if (response.code === 0){
                  this.$message.success("修改成功！")
                  this.submit_success=true;
                }else {
                  this.$message.error("修改失败!"+response.message)
                }

              } else {
                this.activeNames=['2']
                this.$message.error("修改失败！输入有空，请检查！")
                return false;
              }
            });
          } else {
            this.activeNames=['1']
            this.$message.error("修改失败！输入有空，请检查！")
            return false;
          }
        });
      }
      this.loading = false
    },


    /**
     * 检查是否唯一
     * @constructor
     */
    async HospitalNumberIsExist() {
      let caseId = null;
      if (this.questionnaire_info.type === "see"){
        // this.$message.success("无需校验!")
        // return true;
        //获取病例id
        caseId = this.questionnaire_info.id;
      }
      const hospital_number =  this.basic_patient_information.hospital_number
      if (hospital_number) {
        const response = await caseTemplateId17HospitalNumber(hospital_number,{id:caseId});
        if (response.code === 0) {
          this.$message.success("验证通过")
          return true;
        } else {
          this.$message.error(response.message)
          return false;
        }
      } else {
        this.$message.error("请填写后再检查。")
        return true;
      }

    },



    //点击上传图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    /**
     * 暂存之前
     */
    async beforeTemporarySave(){
      this.submitButtonLoading = true
      if (this.questionnaire_info.type === "see"){
        await this.temporaryUpdate()
      }else {
        await this.temporarySave()
      }
      this.submitButtonLoading = false
    },

    //点击暂存
    async temporarySave() {
      //新建一个新的数组，用于处理基本信息数据。
      let form = {};
      let basic_patient_information = this.basic_patient_information
      let surgical_record = this.surgical_record;
      basic_patient_information.show_image_list = this.handleImageList(basic_patient_information.image_list);
      surgical_record.show_image_list = this.handleImageList(surgical_record.image_list)
      form.basic_patient_information = basic_patient_information
      form.surgical_record = surgical_record
      //将所有图片放置到一个字段中
      let image_list = [];
      for (let i in basic_patient_information.show_image_list) {
        image_list.push(basic_patient_information.show_image_list[i])
      }
      for (let i in surgical_record.show_image_list) {
        image_list.push(surgical_record.show_image_list[i])
      }
      const questionnaireInfo = this.questionnaire_info;
      const data = {
        "template_id": questionnaireInfo.template_id,
        "template_link": questionnaireInfo.template_link,
        "patient_id": questionnaireInfo.patient_id,
        "created_by": questionnaireInfo.created_by,
        "template_content": form,
        "coding_image_list": image_list,
        "audit_status": "STAGED"
      }
      const response = await addContent(data);
      if (response.code === 0) {
        this.submit_success = true;
        this.$message.success("暂存成功！")
      } else {
        this.$message.error("提交失败!" + response.message)
      }
    },

    /**
     * 点击暂存更新
     */
    async temporaryUpdate() {
      //新建一个新的数组，用于处理基本信息数据。
      let form = {};
      let basic_patient_information = this.basic_patient_information
      let surgical_record = this.surgical_record;
      basic_patient_information.show_image_list = this.handleImageList(basic_patient_information.image_list);
      surgical_record.show_image_list = this.handleImageList(surgical_record.image_list)

      form.basic_patient_information = basic_patient_information
      form.surgical_record = surgical_record

      //将所有图片放置到一个字段中
      let image_list = [];
      for (let i in basic_patient_information.show_image_list) {
        image_list.push(basic_patient_information.show_image_list[i])
      }
      for (let i in surgical_record.show_image_list) {
        image_list.push(surgical_record.show_image_list[i])
      }

      const questionnaireInfo = this.questionnaire_info;
      const data = {
        "template_content": form,
        "audit_status": "STAGED",
        "coding_image_list": image_list,
      }
      const response = await updateCase(questionnaireInfo.id, data);
      if (response.code === 0) {
        this.$message.success("暂存成功！")
        this.submit_success = true;
      } else {
        this.$message.error("暂存失败!" + response.message)
      }
    }
  }
}
</script>

<style lang="scss">
.el-divider--horizontal{
  margin: 0 0 20px 0;
}
.title{
  text-align: center;
  color: #1ea0fa;
  font-weight: bold;
  font-size: 24px;
}
.footer{
  z-index: 100;
  background-color: #eeeeee;
  height: 70px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  .myButton{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    button{
      width: 30%;
    }
  }
}
</style>