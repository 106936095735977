<template>
  <div>
    <el-result icon="success" title="成功！" subTitle="请退出页面。">
      <template slot="extra">
<!--        <el-button type="primary" size="medium">返回</el-button>-->
      </template>
    </el-result>
  </div>
</template>

<script>
export default {
  name: "submit_success"
}
</script>

<style scoped>

</style>