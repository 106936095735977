<template>
  <div class="select">
    <div><span>注:请将信息填写完整后保存</span></div>
    <el-button @click="submit" type="primary" plain size="medium" style="width: 197px;margin-top: 1px;">选择模板</el-button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MuBanSelect",
  data(){
    return{
      message:"",
    //  questionnaire_info:
    //       { "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IkZGMjVGMjY4LTM0OTUtNDY1MS05Mzc1LTA4M0NERTZBMjI5RSIsIm5hbWUiOiLpgrEifQ.7-7Q6smiAk1jnfbhUuwVeyGYFkd3amakm1Kqykimh8Q",
    //         "patient_id": "2259",
    //         "created_by": "FF25F268-3495-4651-9375-083CDE6A229E",
    //         "template_id": "15",
    //         "template_link": "https://questionnaire.yunicu.com/#/esmolol/m",
    //         //已将添加模板为false，为添加模板为false
    //         "isSelect": "true" },
    }
  },
  props:["select","questionnaire_info"],
  methods:{
    async submit() {
      const response = await this.getTemplates(this.questionnaire_info.created_by,parseInt(this.questionnaire_info.template_id));
      this.message=response
      if (response.code===0){
        this.$message.success("模板添加成功，请填写完整后提交。")
        //需要重新展现出提交按钮，并隐藏添加模板按钮
        this.$store.commit("submit_isSelect_button",false)
      }else {
        this.$message.error("模板添加失败。"+response.message)
      }
    },
    getTemplates(created_by,template_id){
       return  axios.post("https://yunicu-api.yunicu.com/api2/v1/app/myTemplates",{
        created_by,template_id
      },{headers:{"x-token":this.questionnaire_info.token}}).then((res)=>{
        return res.data
       }).catch((e)=>{
         console.log(e)
       })
    }
  }
}
</script>

<style scoped lang="scss">
  .select{
    background-color: white;
    height:70px;
    text-align: center;
    left: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;
    padding-bottom: 10px;
  }
</style>